var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "material-card",
    { staticClass: "mx-4", attrs: { color: "primary" } },
    [
      false
        ? _c(
            "v-card-title",
            [
              _c("v-text-field", {
                attrs: { label: "Buscar", "hide-details": "" },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
              _c(
                "v-btn",
                { attrs: { flat: "", icon: "" } },
                [
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v("mdi-magnify"),
                  ]),
                ],
                1
              ),
              _c("v-spacer"),
            ],
            1
          )
        : _vm._e(),
      _c("v-data-table", {
        attrs: {
          "no-data-text": _vm.$t("no-data-available"),
          "rows-per-page-text": _vm.$t("rows-per-page-text"),
          "rows-per-page-items": _vm.globalrowsPerPageItems,
          headers: _vm.headers,
          items: _vm.logData,
          pagination: _vm.pagination,
        },
        on: {
          "update:pagination": function ($event) {
            _vm.pagination = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "headerCell",
            fn: function ({ header }) {
              return [
                _c("span", {
                  staticClass: "subheading font-weight-light text-primary",
                  domProps: { textContent: _vm._s(header.text) },
                }),
              ]
            },
          },
          {
            key: "items",
            fn: function (props) {
              return [
                _c(
                  "tr",
                  {
                    staticClass: "row",
                    attrs: { color: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.rowClick(props.item)
                      },
                    },
                  },
                  [
                    _c("td", [_vm._v(_vm._s(props.item.fileName))]),
                    _c("td", [_vm._v(_vm._s(props.item.status))]),
                    _c("td", [_vm._v(_vm._s(props.item.idUser.name))]),
                    _c("td", [_vm._v(_vm._s(props.item.date))]),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }