import { render, staticRenderFns } from "./LoadDataList.vue?vue&type=template&id=10597640"
import script from "./LoadDataList.js?vue&type=script&lang=js&external"
export * from "./LoadDataList.js?vue&type=script&lang=js&external"
import style0 from "./LoadDataList.vue?vue&type=style&index=0&id=10597640&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/agent/_work/14/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('10597640')) {
      api.createRecord('10597640', component.options)
    } else {
      api.reload('10597640', component.options)
    }
    module.hot.accept("./LoadDataList.vue?vue&type=template&id=10597640", function () {
      api.rerender('10597640', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Load/LoadDataList/LoadDataList.vue"
export default component.exports