export default {
	data() {
		return {
			logData: [{
				fileName: "Carga-30-agosto.xls",
				status: "Cargado",
				idUser: {
					name: "Juan gonzalez"
				},
				date: "2019-08-30"

			}],
			pagination: {},
			search: "",
			rowsPerPageItems: [5, 10, 15, 20],
			headers: [
				{
					text: "Nombre archivo",
					sortable: false,
					value: "plu"
				},
				{
					sortable: false,
					text: "Estado",
					value: "name"
				},
				{
					sortable: false,
					text: "Usuario",
					value: "isWatched",
					align: "left"
				},
				{
					sortable: false,
					text: "Fecha",
					value: "isApplicable",
					align: "left"
				}
			],
			itemsData: [
				{
					plu: "213213",
					description: "Acetaminofen",
					count: 12,
					nextShippingDate: " 2018-07-07",
					name: "NANCY COMPLEJO",
					identification: "39448156"
				}
			]
		}
	},
	methods: {
		rowClick(item) {
      this.$emit("rowClick", item);
		},
	},
	components: {}
}